<template>
  <TooltipProvider :delay-duration="100">
    <TooltipRoot :open="open || insideOpen">
      <span class="tooltip_wrapper" @mouseover="insideOpen = true" @mouseleave="insideOpen = false">
        <TooltipTrigger as-child>
          <slot></slot>
        </TooltipTrigger>
      </span>
      <TooltipPortal v-if="content">
        <TooltipContent
          v-bind="newContentProps"
          class="TooltipContent"
          :class="{ 'multi-line': allowLineReturns }"
          hide-when-detached
        >
          <slot name="content">
            <template v-if="allowLineReturns">
              <div class="tooltip-content-wrapper">
                <template v-for="(line, index) in contentLines" :key="index">
                  <div>{{ line }}</div>
                </template>
              </div>
            </template>
            <template v-else>{{ content }}</template>
          </slot>
          <TooltipArrow class="TooltipArrow" />
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipProvider,
  type TooltipContentProps,
} from "radix-vue";

interface TooltipProps {
  contentProps?: TooltipContentProps;
  content?: string;
  open?: boolean;
  allowLineReturns?: boolean;
}

const props = withDefaults(defineProps<TooltipProps>(), {
  contentProps: () => ({}),
  content: "",
  open: false,
  allowLineReturns: false,
});

const newContentProps = computed<TooltipContentProps>(() => ({
  sideOffset: 4,
  avoidCollisions: true,
  ...props.contentProps,
}));

const insideOpen = ref(false);

const contentLines = computed(() => props.content.split("\n"));
</script>

<style lang="scss">
.tooltip_wrapper {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.TooltipContent {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: var(--gray-1);
  padding: 8px;
  gap: 16px;
  font-weight: 500;
  color: var(--white);
  max-width: 300px; // Adjust this value as needed

  &.multi-line {
    height: auto;
    min-height: 32px;
    align-items: flex-start;
  }
}

.tooltip-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.TooltipArrow {
  fill: var(--gray-1);
}
</style>
